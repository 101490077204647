<template>
  <div>
    <b-row
      v-if="first_name != ''"
      align-h="start"
    >
      <b-col
        cols="5"
        class="cardHie ml-1 pl-1 mb-1"
      >
        <b-row>
          <b-col
            cols="12"
            class="pt-2 pb-1 ml-1"
          >
            <h5 class="text-primary">
              <feather-icon
                icon="FileMinusIcon"
                class="mr-75"
              />ข้อมูลผุ้ใช้งาน
            </h5>
            <hr>
            <table class="mt-xl-0 w-75">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CreditCardIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Username</span>
                </th>
                <th class="pb-50">
                  {{ username }}
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Name</span>
                </th>
                <th class="pb-50">
                  {{ title }} {{ first_name }} {{ last_name }}
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Role</span>
                </th>
                <th class="pb-50">
                  <b-badge :variant="type[1][typeTo]">
                    {{ type[0][typeTo] }}
                  </b-badge>
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="MailIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Email</span>
                </th>
                <th class="pb-50">
                  {{ emailTo }}
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Contact</span>
                </th>
                <th class="pb-50">
                  {{ phone }}
                </th>
              </tr>
            </table>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="d-flex flex-wrap">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-primary
                size="sm"
                variant="primary"
              >
                แก้ไขข้อมูล
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-primary1
                size="sm"
                class="ml-1"
                variant="primary"
              >
                เปลี่ยน Password
              </b-button>
              <!-- <b-button
                size="sm"
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button> -->
            </div>
          </div>
        </div>
        <br>
      </b-col>
    </b-row>
    <!-- <b-card v-if="first_name != ''">
      <b-row> -->
    <!-- <b-col
          cols="5"
        >
          <table class="mt-2 mt-xl-0 w-75 ml-2">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="mr-75">
                {{ username }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Name</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ title }} {{ first_name }} {{ last_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-badge :variant="type[1][typeTo]">
                  {{ type[0][typeTo] }}
                </b-badge>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Email</span>
              </th>
              <td class="pb-50">
                {{ emailTo }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                {{ phone }}
              </td>
            </tr>
          </table>
          <br>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <div class="d-flex flex-column ml-1">
                <div class="d-flex flex-wrap">
                  <b-button
                    size="sm"
                    variant="primary"
                  >
                    Edit
                  </b-button>
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    class="ml-1"
                  >
                    Delete
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-col> -->
    <!-- <b-col
          cols="5"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="mr-75">
                {{ username }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ title }} {{ first_name }} {{ last_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ title }} {{ first_name }} {{ last_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Country</span>
              </th>
              <td class="pb-50">
                {{ title }} {{ first_name }} {{ last_name }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                {{ title }} {{ first_name }} {{ last_name }}
              </td>
            </tr>
          </table>
          <br>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <div class="d-flex flex-column ml-1">
                <div class="d-flex flex-wrap">
                  <b-button
                    size="sm"
                    variant="primary"
                  >
                    Edit
                  </b-button>
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    class="ml-1"
                  >
                    Delete
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-col> -->
    <!-- </b-row>
    </b-card> -->
    <b-card>
      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <!-- <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group> -->
        </b-col>
        <b-col
          md="4"
          sm="8"
          class="my-1"
        >
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- none --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="12"
        >
          <b-table
            hover
            responsive
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            :sticky-header="stickyHeader"
            :select-mode="selectMode"
            :head-variant="headVariant"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
          >
            <!-- Status -->
            <template #cell(type)="data">
              <b-badge
                class="badge-glow d-block"
                :variant="type[1][data.value]"
              >
                {{ type[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(username)="data">
              <b-badge variant="light-dark">
                <feather-icon
                  icon="UserIcon"
                  class="mr-25"
                />
                <span> {{ data.item.username }}</span>
              </b-badge>
            </template>
            <template #cell(fullName)="data">
              <b-badge variant="light-dark">
                <span>
                  {{ data.item.title }} {{ data.item.fName }}
                  {{ data.item.lName }}
                </span>
              </b-badge>
            </template>
            <template #cell(email)="data">
              <b-badge variant="light-dark">
                <span> {{ data.item.email }}</span>
              </b-badge>
            </template>
            <template #cell(phone)="data">
              <b-badge variant="light-dark">
                <span> {{ data.item.phone }}</span>
              </b-badge>
            </template>
          </b-table>
        </b-col>

        <!-- <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col> -->
      </b-row>
    </b-card>
    <b-modal
      id="modal-primary"
      hide-footer
      ok-only
      disabled
      ok-variant="danger"
      ok-title="ปิด"
      modal-class="modal-primary"
      size="lg"
      centered
      title="แก้ไขข้อมูลผู้ใช้"
    >
      <div>
        <b-row>
          <b-col md="2">
            <b-form-group
              label="คำนำหน้า"
              label-for="mc-first-name"
            >
              <b-form-input
                id="mc-first-name"
                v-model="title"
                placeholder="คำนำหน้า"
              /> </b-form-group></b-col>
          <b-col md="5">
            <b-form-group
              label="ชื่อ"
              label-for="mc-first-name"
            >
              <b-form-input
                id="mc-first-name"
                v-model="first_name"
                placeholder="First Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group
              label="นามสกุล"
              label-for="mc-last-name"
            >
              <b-form-input
                id="mc-last-name"
                v-model="last_name"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="mc-email"
              label="ประเภทผู้ใช้งาน"
            >
              <b-form-select
                v-model="typeTo"
                class="mb-1"
                :options="options"
                :state="selected === null ? false : true"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="เบอร์โทร"
              label-for="mc-city"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SmartphoneIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="hi-number"
                  v-model="phone"
                  type="number"
                  placeholder="เบอร์โทร"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="mc-email"
              label="Email"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="hi-email"
                  v-model="emailTo"
                  type="email"
                  placeholder="Email"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="ที่อยู่"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="address"
                  placeholder="ที่อยู่"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" />
          <b-col md="4" />
          <b-col cols="8" />
          <!-- submit and reset -->
          <b-col cols="12">
            <br>
          </b-col>
          <b-col cols="4" />
          <b-col
            cols="4"
            class="text-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="updateUserhie()"
            >
              บันทึกข้อมูล
            </b-button>
          </b-col>
          <b-col cols="4" />
        </b-row>
        <br>
      </div>
    </b-modal>
    <b-modal
      id="modal-primary1"
      hide-footer
      ok-only
      disabled
      ok-variant="danger"
      ok-title="ปิด"
      modal-class="modal-primary"
      size="lg"
      centered
      title="แก้ไข Password"
    >
      <div>
        <b-row>
          <b-col md="2" />
          <b-col md="4">
            <b-form-group
              label-for="mc-email"
              label="Username"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend
                  is-text
                  disabled
                >
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="hi-first-name"
                  v-model="username"
                  disabled
                  placeholder="Username"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="mc-email"
              label="Password"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="hi-password"
                  v-model="password_new"
                  type="password"
                  placeholder="Password"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2" />
          <!-- submit and reset -->
          <b-col cols="12">
            <br>
          </b-col>
          <b-col cols="4" />
          <b-col
            cols="4"
            class="text-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="updateUserhie_Password()"
            >
              บันทึกข้อมูล
            </b-button>
          </b-col>
          <b-col cols="4" />
        </b-row>
        <br>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  VBModal,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  // props: ['hn', 'name', 'txn', 'labdetails'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // BFormDatepicker,
    BTable,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      stickyHeader: true,
      selectMode: 'single',
      headVariant: 'light',
      codeDocSri: '',
      InfoDoc: [],
      title: '',
      first_name: '',
      last_name: '',
      fullNameDoc: '',
      codeDoc: '',
      docLicense: '',
      alertStatus: false,
      request_to: 'โรงพยาบาลมหาราชนครเชียงใหม่',
      value: '',
      emailValue: '',
      name: '',
      required,
      email,
      patient_title: '',
      patient_first_name: '',
      patient_last_name: '',
      patient_hn: '',
      patient_reason: 'เพื่อการรักษา',
      patient_info_requested: '',
      setRequestStatus: null,
      setUpdateUserhi: '',
      itemshie: [],
      nextTodoId: 2,
      fields: [
        {
          key: 'type',
          label: 'type',
          sortable: true,
        },
        {
          key: 'username',
          label: 'USERNAME',
        },
        'fullName',
        {
          key: 'phone',
          label: 'phone',
        },
        {
          key: 'email',
          label: 'Email',
        },
      ],
      fieldPatients: [
        {
          key: 'hn',
          label: 'hn',
        },
        {
          key: 'Title',
          label: 'title',
        },
        {
          key: 'FirstName',
          label: 'FIRST NAME',
        },
        {
          key: 'LastName',
          label: 'LAST NAME',
        },
        'delete',
      ],
      itemsHIE: [],
      type: [
        {
          5: 'ผู้ป่วย',
          1: 'แพทย์',
          3: 'พนักงานสวนดอก',
          2: 'พนักงานศูนย์ศรีพัฒน์',
          4: 'ญาติผู้ป่วย',
          6: 'บริษัทประกัน',
          9: 'ผู้ดูแลระบบ',
        },
        {
          5: 'light-primary',
          1: 'light-success',
          6: 'light-dark',
          2: 'light-warning',
          3: 'light-info',
          9: 'light-danger',
          4: 'light-secondary',
        },
      ],
      perPage: 20,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      selected: [],
      selected1: [],
      id_Bset: '',
      codeHnSri: '',
      getPatientDetail: [],
      items: [],
      fullName: '',
      itemsHN: [],
      username: '',
      emailTo: null,
      phone: '',
      typeTo: '',
      password: '',
      password_new: '',
      address: null,
      idSelect: '',
      options: [
        { value: null, text: 'Please select role' },
        { value: '1', text: 'แพทย์' },
        { value: '2', text: 'พนักงานศูนย์ศรีพัฒน์' },
        { value: '3', text: 'พนักงานสวนดอก' },
        { value: '4', text: 'ญาติผู้ป่วย' },
        { value: '5', text: 'ผู้ป่วย' },
        { value: '6', text: 'บริษัทประกัน' },
      ],
      confirmpassword: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // this.getDocInfo()
    this.getUserhie()
  },
  methods: {
    // getPatient_hie() {
    // },
    async getUserhie() {
      await this.$http({
        url: 'api/User/getUser/ALL',
        method: 'get',
        data: {},
      }).then(response => {
        // console.log(response.data)
        this.items = response.data
      })
      // await this.totalRowsHIE()
    },
    totalRowsHIE() {
      this.totalRows = this.items.length
    },
    async updateUserhie() {
      await this.$http({
        url: 'api/User/UpdateUser',
        method: 'post',
        data: {
          ID: this.id_Bset,
          Type: this.typeTo,
          Title: this.title,
          FName: this.first_name,
          LName: this.last_name,
          Address: this.address,
          Phone: this.phone,
          Email: this.emailTo,
          // Username: this.username,
          // Password: this.password,
        },
      }).then(response => {
        this.setUpdateUserhi = response.data
      })
      await this.alertStatus_User()
    },
    async updateUserhie_Password() {
      await this.$http({
        url: 'api/User/UpdatePassword',
        method: 'post',
        data: {
          ID: this.id_Bset,
          Username: this.username,
          Password: this.password_new,
        },
      }).then(response => {
        this.setUpdateUserhi = response.data
      })
      await this.alertStatus_User()
    },
    async alertStatus_User() {
      // console.log('this.setUpdateUserhi', this.setUpdateUserhi)
      if (this.setUpdateUserhi === 'ok') {
        await this.$swal({
          title: 'แก้ไขข้อมูลสำเร็จ',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        // await this.$router.go()
        await this.getUserhie()
      } else {
        this.$swal({
          title: 'แก้ไขข้อมูลไม่สำเร็จ',
          text: ' กรุณาตรวจสอบหรือแก้ไขข้อมูลใหม่',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    async info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      this.selected1 = item
      // console.log(item)
    },
    async Minusinfo(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      await this.deleteobj(index)
    },
    deleteobj(index) {
      // delete this.itemshie[index]
      // console.log('this.itemshie:', this.itemshie, index)
      if (index === 0) {
        this.itemshie.shift()
        this.itemsHN.shift()
      } else {
        this.itemshie.splice(index, index)
        this.itemsHN.splice(index, index)
      }
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onRowSelected(items) {
      this.selected = items
      // console.log(items[0].id)
      await this.setName()
    },
    setName() {
      this.title = this.selected[0].title
      this.first_name = this.selected[0].fName
      this.last_name = this.selected[0].lName
      this.id_Bset = this.selected[0].id
      this.username = this.selected[0].username
      this.emailTo = this.selected[0].email
      this.phone = this.selected[0].phone
      this.typeTo = this.selected[0].type
      this.address = this.selected[0].address
      this.password = this.selected[0].password
      this.idSelect = this.selected[0].id
      this.makeToast('success')
    },
    makeToast(variant = null) {
      this.$bvToast.toast(
        `${this.title} ${this.first_name} ${this.last_name}`,
        {
          title: 'เลือก USER สำเร็จ',
          variant,
          solid: true,
        },
      )
    },
  },
}
</script>
<style>
.cardHie {
  border-radius: 10px;
  border: 1px solid rgb(241, 241, 241);
  background-color: rgb(255, 255, 255) !important;
}
</style>
